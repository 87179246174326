/**
 * Pushes an event and optional payload to the GTM dataLayer.
 * Initializes window.dataLayer if it doesn't exist.
 *
 * @param {string} eventName - The name of the event to push (e.g., 'gatsbyRouteLoaded', 'virtualPageView'). Used for GTM Custom Event triggers.
 * @param {Record<string, any>} [payload={}] - Optional key-value pairs to push along with the event. Can include page path, title, user data etc.
 * @param {boolean} [debug=false] - If true, logs the pushed data to the console for debugging.
 * @return {void}
 */
export function logDataLayerEvent(
  eventName: string,
  payload: Record<string, any> = {},
  debug: boolean = false
): void {
  if (typeof window === "undefined") {
    if (debug) {
      // eslint-disable-next-line no-console
      console.log("[logDataLayerEvent] Skipping: window object not found.");
    }
    return;
  }

  if (!eventName || typeof eventName !== "string" || eventName.trim() === "") {
    // eslint-disable-next-line no-console
    console.error(
      "[logDataLayerEvent] Error: eventName is required and must be a non-empty string."
    );
    return;
  }

  try {
    // Ensure dataLayer exists and is an array
    window.dataLayer = window.dataLayer || [];
    if (!Array.isArray(window.dataLayer)) {
      // eslint-disable-next-line no-console
      console.error(
        "[logDataLayerEvent] Error: window.dataLayer exists but is not an array. GTM might not function correctly.",
        window.dataLayer
      );
      return;
    }

    const dataToPush = {
      event: eventName.trim(),
      ...payload,
    };

    if (debug) {
      // eslint-disable-next-line no-console
      console.log(
        "[logDataLayerEvent] Pushing to dataLayer:",
        JSON.stringify(dataToPush)
      );
    }

    window.dataLayer.push(dataToPush);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("[logDataLayerEvent] Error pushing to dataLayer:", error);
  }
}
